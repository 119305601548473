<template>
  <div class="page page-center">
    <div class="container container-tight py-4">
      <div class="text-center mb-3">
        <a href="/" class="navbar-brand navbar-brand-autodark"><img src="@/assets/static/logo-white.svg" height="45" alt=""></a>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
}
</script>
