import {axios as http} from "@/plugins/http"
import Helper from "@/services/Helper"

function getFormData(obj) {
    const formData = new FormData()

    Object.entries(obj).forEach(([key, val]) => {
        formData.append(key, val)
    })

    return formData
}

export default class Api {
    static count(payload) {
        let query = {}

        if (!payload.channel_ids) {
            throw new Error("Отсутствует channel_ids")
        }

        query = Helper.mergeObject(query, {
            "channel_ids": payload?.channel_ids,
        })

        if (payload?.is_moderate !== undefined) {
            query = Helper.mergeObject(query, {
                "is_moderate": payload.is_moderate,
            })
        }

        if (payload?.is_reject !== undefined) {
            query = Helper.mergeObject(query, {
                "is_reject": payload.is_reject,
            })
        }


        return http.post("/api/v1/comment/count", getFormData(query)).then((resp) => {
            return resp.data["items"]
        })
    }
}
