<template>
  <metainfo></metainfo>

  <div class="card_ card-md_">
    <div class="card-body_">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <div class="col-xl-auto py-3">
              <a :href="getLoginLink()" class="btn btn-bitbucket" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-brand-telegram">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"></path>
                </svg>
                Войти через Telegram
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from 'vue'
import {useMeta} from "vue-meta"
import {useRouter} from "vue-router"
import {useVuelidate} from '@vuelidate/core'
import {required} from '@/plugins/vuelidator'
import Auth from "@/modules/auth/services/Auth"
import SocketRegister from "@/plugins/ws/services/SocketRegister"
import AuthToken from "@/modules/auth/services/Token"
import Helper from "@/services/Helper"
import AuthClient from "@/modules/auth/services/Client"
import RoleStore from "@/modules/role/services/Store"

export default defineComponent({
  name: "Login",
  setup(_, {expose}) {
    useMeta({title: "Вход в HPanel"})

    const isBusy = ref(false)

    const $externalResults = reactive({})

    const state = reactive({
      login: "",
      password: "",
    })

    const rules = {
      login: {required},
      password: {required},
    }

    const v$ = useVuelidate(rules, state, {$externalResults})

    const router = useRouter()

    function setHookAuthWs() {
      SocketRegister.handle((payload) => {
        let data = JSON.parse(payload)

        if (data["token"] === undefined) {
          return
        }

        setToken(data["token"]).then(async () => {
          await Auth.authenticate()
          await RoleStore.setPermissions()
          await router.push({name: "Dashboard"})
        })
      })

      function setToken(token) {
        return new Promise((resolve) => {
          AuthToken.set(token)
          resolve()
        })
      }
    }

    function getLoginLink() {
      let clientId = AuthClient.get()

      return "https://t.me/" + Helper.getAdminBot() + "?start=login_clientId-" + clientId
    }

    expose({ setHookAuthWs })

    return {
      state,
      v$,
      isBusy,
      getLoginLink,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.name === 'Login') {
        vm.setHookAuthWs()
      }
    });
  }
})
</script>

<style scoped>

</style>