import dashboard from "@/modules/dashboard"
import auth from "@/modules/auth"
import post from "@/modules/post"
import user from "@/modules/user"
import shops from "@/modules/shops"
import role from "@/modules/role"
import statistic from "@/modules/statistic"
import catalog from "@/modules/catalog"
import complaints from "@/modules/complaints"
import promopushka from "@/modules/promopushka"
import comment from "@/modules/comment"

export default class Module {
  static handle(app) {
    app.use(dashboard)
    app.use(auth)
    app.use(post)
    app.use(user)
    app.use(shops)
    app.use(role)
    app.use(statistic)
    app.use(catalog)
    app.use(complaints)
    app.use(promopushka)
    app.use(comment)
  }
}
