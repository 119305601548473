<template>
  <div id="promopushka-list">
    <div class="row row-cards">
      <template v-if="isNotFound(items) && !busy">
        <span class="items-not-found">Ничего не найдено</span>
      </template>
      <template v-else>
        <div class="col-lg-4 col-sm-6" v-for="item in items" :key="item.id">
          <div class="card">
            <template v-if="item.deleted">
              <div class="ribbon bg-red">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <line x1="4" y1="7" x2="20" y2="7"></line>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                </svg> <span v-html="getDate(item.deleted)"></span>
              </div>
            </template>
            <div class="close-full-show" v-if="isMoreShow(item.id)" @click.prevent="onMoreHide(item.id)">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M18 6l-12 12"></path>
                <path d="M6 6l12 12"></path>
              </svg>
            </div>
            <span>
              <div class="card-img-top card-img-hover" @click.prevent="onMoreShowHide(item.id)" :class="{ 'card-img-top_show': isMoreShow(item.id) }">
                <img :src="getImageStub()" class="img-card">
                <div class="card-img-wrap">
                  <img :src="getImage(item.shop.host, item.shop.image, 100, 100)" class="img-card-shop" v-if="item.shop">
                  <div>
                    <div>
                      <span class="badge bg-purple">{{ getTypeOfSteps(item) }}</span> <span class="badge bg-purple">{{ getTypeOfCoupon(item) }}</span>
                    </div>
                    <b v-text="item.title"></b>
                  </div>
                </div>
                <div class="card-info">
                  <PostSaleStatistic :data="{
                    post_id: item.id,
                    type_id: getStatisticTypeId(),
                  }" />
                  <PostPointStatistic :data="{
                    post_id: item.id,
                    type_id: getStatisticTypeId(),
                  }" />
                </div>
              </div>
              <div class="card-body post-item" @click="onMoreShow(item.id)">
                <div class="text-muted text-long mb-2" v-show="isMoreShow(item.id)" v-html="item.description"></div>
                <div class="text-muted card-text-sh" v-show="!isMoreShow(item.id)" v-html="item.description_short"></div>
                <div class="my-2" v-if="!isDeleted(item)">
                  <router-link :to="{ name: 'CouponUpdate', params: { id: item.id } }" class="edit-post toggle-show-post" @click.prevent="onMoreShow(item.id)">Редактировать</router-link>
                </div>
              </div>
            </span>

            <div class="card-footer">
              <div class="d-flex align-items-center">
                <span v-if="getUser(item.created_by)" class="avatar me-3" :style="{ backgroundImage: 'url(' + getImage(getUser(item.created_by).avatar_host, getUser(item.created_by).avatar_name, 60) + ')' }"></span>
                <div v-if="getUser(item.created_by)" class="author">
                  <div class="author__name">{{ getUser(item.created_by).name }}</div>
                  <div class="channel">
                    <Channel />
                  </div>
                </div>
                
                <template v-if="item.published">
                  <div class="ms-auto text-right">
                    <div>Опубликовано:</div>
                    <div class="text-muted" v-html="getDate(item.published)"></div>
                  </div>
                </template>

                <template v-if="!item.published">
                  <div class="ms-auto text-right">
                    <div>Создано:</div>
                    <div class="text-muted" v-html="getDate(item.created)"></div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="loader-posts">
        <div class="spinner-border" v-show="busy"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import Api from "@/modules/promopushka/components/list/services/Api"
import Channel from "@/modules/promopushka/components/list/components/channel/Channel"
import element from "@/modules/promopushka/services/element"
import {defineComponent, onMounted, ref, watch} from "vue"
import Helper from "@/services/Helper"
import DTBackend from "@/services/DTBackend"
import Image from "@/services/Image"
import PostSaleStatistic from "@/components/statistic/sale/Sale"
import {fill as saleFill} from "@/components/statistic/sale/services/statistic"
import PostPointStatistic from "@/components/statistic/point/Point"
import {fill as pointFill} from "@/components/statistic/point/services/statistic"
import cfg from "@/services/Config"

export default defineComponent({
  name: "List",
  components: {
    Channel,
    PostSaleStatistic,
    PostPointStatistic,
  },
  props: {
    query: {
      type: Object,
      required: true
    },
    clearResult: {
      type: Boolean,
      required: true
    }
  },
  setup(props, {emit}) {
    let busy = ref(false)
    let items = ref([])
    let eofItems = ref(false)
    let countItems = ref(0)
    let actions = ref([])
    let users = ref([])

    onMounted(() => {
      Api.users().then((resp) => {
        users.value = resp.data.items
      })
    })

    watch(() => props.query, (query) => {
      busy.value = true

      if (props.clearResult) {
        clearResult()
      }

      load(query)
    })

    function clearResult() {
      eofItems.value = false
      countItems.value = 0
      items.value = []
    }

    function load(query, offset = null) {
      if (offset !== null) {
        query.offset = offset;
      }

      Api.items(query).then((resp) => {
        let data = resp.data.data

        items.value.push(...data.items)

        if (data.items.length === 0) {
          eofItems.value = true
        }

        countItems.value = data.count

        loadFinal(data.items);
      }).finally(() => {
        busy.value = false

        emit("onRender", {
          "oef_items": eofItems.value,
          "count_items": countItems.value
        })
      })
    }

    function loadFinal(items) {
      saleFill(items, getStatisticTypeId())
      pointFill(items, getStatisticTypeId())
    }

    function getUser(id) {
      return users.value.find((item) => item.id === id)
    }

    function getImage(host, name, w = null, h = null) {
      return Image.handle(host, name, w, h)
    }

    function getImageStub() {
      return "/assets/static/promopushka/no_image.jpg"
    }

    function isNotFound(items) {
      return Helper.isEmptyObject(items)
    }

    function onMoreShow(coupon_id) {
      actions.value[coupon_id] = {
        show: true
      }
    }

    function onMoreHide(coupon_id) {
      actions.value[coupon_id] = {
        show: false
      }
    }

    function onMoreShowHide(coupon_id) {
      actions.value[coupon_id] = {
        show: !this.isMoreShow(coupon_id)
      }
    }

    function isMoreShow(coupon_id) {
      return actions.value[coupon_id]?.show === true
    }

    function getDate(date) {
      let result, dt
      dt = new DTBackend(date)
      result = dt.get("%D.%M.%y <span>%H:%I</span>")

      return result
    }

    function getTypeOfSteps(item) {
      return element.getTypeOfSteps(item)
    }

    function getTypeOfCoupon(item) {
      return element.getTypeOfCoupon(item)
    }

    function getStatisticTypeId() {
      return cfg._("statistic", "types", "promopushka")
    }

    function isDeleted(item) {
      return item.del == 1
    }

    return {
      busy,
      items,
      isNotFound,
      actions,
      onMoreShow,
      onMoreHide,
      isMoreShow,
      getImageStub,
      onMoreShowHide,
      getDate,
      getImage,
      getUser,
      getTypeOfSteps,
      getTypeOfCoupon,
      getStatisticTypeId,
      isDeleted
    }
  }
})
</script>

<style scoped></style>
