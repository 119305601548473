<template>
  <metainfo></metainfo>
  <template v-if="isForbiddenPage()">
    <NoPermission />
  </template>
  <template v-else>
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="d-flex g-2 align-items-center">
          <div class="flex-grow-1 me-1">
            <h2 class="page-title">
              Каталог
            </h2>
          </div>
          <div>
            <template v-if="isDragMode()">
              <span class="me-2">Перемещение:</span>
            </template>
            <template v-if="isDragMode()">
              <button @click="onStoreDragMode" class="btn me-3 btn-primary" :class="{'disabled': !hasDragStore()}">Сохранить</button>
            </template>
            <template v-if="!isDragMode()">
              <button @click="onActiveDragMode" class="btn me-3 btn-primary">Режим перемещения</button>
            </template>
            <template v-else>
              <button @click="onCancelDragMode" class="btn me-3 btn-danger">Отменить</button>
            </template>
            <a class="ms-1 btn btn-ghost-dark" @click="onCreate(null)" data-bs-toggle="modal" data-bs-target="#modalStore">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-playlist-add icon-catalog" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M19 8h-14" />
                <path d="M5 12h9" />
                <path d="M11 16h-6" />
                <path d="M15 16h6" />
                <path d="M18 13v6" />
              </svg>
              Создать категорию
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="page-body">
      <div class="container-xl">
        <div class="card">
          <div class="card-body">
            <div v-if="isEventProcess()" class="d-flex justify-content-center">
              <span v-if="isCatalogLoading()" class="spinner-border spinner-border-sm" role="status"></span>
            </div>
            <div v-else>
              <template v-if="isAnyCategories()">
              <nested-draggable @start="onDragStart" @end="onDragEnd" :draggableCatalog="dragMode" :collapse="{ collapse: true }" :folders="treeList" @create-item="onCreate" @edit-item="onUpdate" @delete-item="onDelete" />
              </template>
              <template v-else>
                Категорий не найдено
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-blur fade" id="modalStore" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isCreateAction() ? 'Создать категорию' : 'Редактировать категорию' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="isCreateAction()" class="mb-3">
              <span>Будет создано в </span>
              <template v-if="isHasCurrentElement()">
                "<b><span>{{ getCurrentElementVal('name') }}</span></b>"
              </template>
              <template v-else>
                "<b><span>Корень</span></b>"
              </template>
            </div>
            <div class="mb-3">
              <label class="form-label">Имя</label>
              <input type="text" id="modalStoreInput" class="form-control" name="example-text-input" v-model="categoryForm['name']" placeholder="Название" :class="{'is-invalid': v$.name.$invalid && v$.name.$dirty}"/>
              <div class="invalid-feedback" v-for="error of v$.name.$silentErrors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
              Отмена
            </a>
            <a href="#" class="btn btn-primary ms-auto" @click="onStore">
              <span class="spinner-border spinner-border-sm me-2" v-show="isPendingStore()"></span>
              <svg v-if="isCreateAction()" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 5l0 14" />
                <path d="M5 12l14 0" />
              </svg>
              {{ isCreateAction() ? 'Создать' : 'Изменить' }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-blur fade" id="modal-danger" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          <div class="modal-status bg-danger"></div>
          <div class="modal-body text-center py-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-danger icon-lg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 9v4"></path>
              <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z"></path>
              <path d="M12 16h.01"></path>
            </svg>
            <h3>Вы уверены?</h3>
            <div class="text-secondary">Вы действительно хотите удалить <b>"{{ getCurrentElementVal('name') }}"</b>?</div>
          </div>
          <div class="modal-footer">
            <div class="w-100">
              <div class="row">
                <div class="col"><a href="#" class="btn w-100" data-bs-dismiss="modal">
                    Отмена
                  </a></div>
                <div class="col">
                  <a href="#" class="btn btn-danger w-100" data-bs-dismiss="modal" @click="onDeleteConfirm">
                    Удалить
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue"
import NestedDraggable from "../components/Nested"
import Api from "../services/ApiBase"
import Helper from "@/services/Helper"
import Notify from "@/services/Notify"
import {required} from "@/plugins/vuelidator"
import {useVuelidate} from "@vuelidate/core"
import NoPermission from "./no_permission/Index"
import Permission from "@/services/Permission"

export default defineComponent({
  name: "Catalog",
  components: {
    NestedDraggable,
    NoPermission,
  },
  setup() {
    let treeList = ref([])
    let dragStore = ref({})
    let dragMode = ref(false)
    let pendingStore = ref(false)
    let categoryForm = ref({})
    let noPermission = ref(false)
    let action = ref({
      create: false,
      update: false,
      delete: false,
    })
    let currentElement = ref({})
    let events = ref({
      loading: true,
    })
    let modalStore = ref({})

    const rules = {
      name: {
        required,
      },
    }

    const v$ = useVuelidate(rules, categoryForm)

    function validate(fn) {
      return v$.value.$validate().then((resp) => {
        if (resp === true) {
          fn()
        }
      })
    }

    function resetValidations() {
      v$.value.$reset()
    }

    onMounted(() => {
      if (!checkPermission()) {
        return
      }

      modalInit()
      fillCategories()
    })

    function checkPermission() {
      if (!Permission.hasPermission("catalog")) {
        setNoPermission()
        return false
      }

      return true
    }

    function modalInit() {
      let modalStoreEl = document.getElementById('modalStore')
      let modalStoreInputEl = document.getElementById('modalStoreInput');

      modalStore.value = new bootstrap.Modal(modalStoreEl);

      modalStoreEl.addEventListener('shown.bs.modal', () => {
        modalStoreInputEl.focus()
      })
    }

    function fillCategories() {
      startLoading()
      return Api.categories().then((resp) => {
        console.log(resp)
        buildTree(resp.data["items"])
      }).finally(() => {
        endLoading()
      })
    }

    function startLoading() {
      events.value.loading = true
    }

    function endLoading() {
      events.value.loading = false
    }

    function buildTree(response) {
      let el = []

      response.forEach((o) => {
        o["name"] = o["category_name"]
        o["id"] = o["category_id"]
        o["folders"] = o["folders"] || []
        o["root"] = o["category_parent_id"] === undefined

        Object.assign(el[o["category_id"]] = el[o["category_id"]] || {}, o)

        el[o["category_parent_id"]] = el[o["category_parent_id"]] || {}
        el[o["category_parent_id"]].folders = el[o["category_parent_id"]].folders || []
        el[o["category_parent_id"]].folders.push(el[o["category_id"]])
      });

      if (el.length) {
        el.length = 1
        treeList.value = buildList(el[0].folders)
      }
    }

    function setAction(val) {
      resetActions()
      if (val === "create") {
        action.value.create = true
      } else if (val === "update") {
        action.value.update = true
      } else if (val === "delete") {
        action.value.delete = true
      }  else {
        throw new Error("Неверное действие")
      }
    }

    function resetActions() {
      action.value = {
        create: false,
        update: false,
        delete: false,
      }
    }

    function isCreateAction() {
      return action.value.create === true
    }

    function isUpdateAction() {
      return action.value.update === true
    }

    function isDeleteAction() {
      return action.value.delete === true
    }

    function isEventProcess() {
      return isCatalogLoading()
    }

    function isCatalogLoading() {
      return events.value.loading
    }

    function buildList(list) {
      if (list.length) {
        return list.map(item => {

          let newItem = {};
          for (let key in item) {
            if (key === 'folders') {
              newItem[key] = buildList(item[key])
            } else {
              newItem[key] = item[key]
            }
          }

          if (item.folders.length === 0) {
            newItem.isCollapse = {collapse: true}
          } else {
            newItem.isCollapse = {collapse: false}
          }

          return newItem
        })
      }

      return []
    }

    function setCurrentElement(element) {
      currentElement.value = element
    }

    function getCurrentElementVal(key) {
      if (key !== null && key !== undefined) {
        return currentElement.value[key]
      } else {
        return currentElement.value
      }
    }

    function setCurrentElementVal(key, val) {
      return currentElement.value[key] = val
    }

    function isHasCurrentElement() {
      return !Helper.isEmptyObject(currentElement.value)
    }

    function setCategoryForm({name}) {
      categoryForm.value = {
        name: name,
      }
    }

    function getCategoryForm(key) {
      return categoryForm.value[key]
    }

    function onCreate(element) {
      setCategoryForm({name: ""})
      setAction("create")

      if (element === null) {
        setCurrentElement({})
      } else {
        setCurrentElement(element)
      }
    }

    function onUpdate(element) {
      setCategoryForm({name: element.name})
      setAction("update")

      setCurrentElement(element)
    }

    function onDelete(element) {
      setAction("delete")

      setCurrentElement(element)
    }

    function onDeleteConfirm() {
      let ids = getTreeIds(getCurrentElementVal("folders"), [])
      ids.unshift(getCurrentElementVal("id"))

      deleteApi(ids).then((resp) => {
        console.log(resp)
        treeList.value = deleteItem(treeList.value, currentElement.value)

        if (ids.length === 1) {
          Notify.success("Категория удалена");
        } else {
          Notify.success("Дерево категорий удалено");
        }
      })
    }

    function getTreeIds(list, result) {
      Object.values(list).forEach(value => {
        if (value["folders"].length) {
          getTreeIds(value["folders"], result)
        }
        result.push(value["id"])
      })

      return result
    }

    function deActiveDragMode() {
      dragMode.value = false
    }

    function onActiveDragMode() {
      dragMode.value = true
    }

    function onCancelDragMode() {
      fillCategories().then(() => {
        clearDragStore()
        deActiveDragMode()

        Notify.success("Перенос отменен");
      })
    }
    
    function onStoreDragMode() {
      if (!hasDragStore()) {
        Notify.error("Переместите хотя бы один из элементов категории");
      } else {
        console.log("onStoreDragMode API Request", getDragStore())

        moveApi(getDragStore()).then(() => {
          clearDragStore()
          deActiveDragMode()

          Notify.success("Перемещено");
        })
      }
    }

    function deleteItem(list, element) {
      return list.filter((item) => {
        if (item.folders) {
          item.folders = deleteItem(item.folders, element)
        }

        return item.id !== element.id
      })
    }

    function onStore() {
      if (isCreateAction()) {
        validate(function () {
          let parentId = 0
          let name = getCategoryForm("name")

          if (isHasCurrentElement()) {
            parentId = getCurrentElementVal("category_id")
          }

          startPending()
          createApi(0, parentId, name).then((resp) => {
            let data = resp.data["items"][0]

            let item = {
              id: data["category_id"],
              name: data["category_name"],
              folders: [],
              isCollapse: {collapse: true},
            };

            if (isHasCurrentElement()) {
              getCurrentElementVal("folders").push(item)
            } else {
              treeList.value.push(item)
            }

            resetActions()
            resetValidations()

            Notify.success("Категория создана");
          }).then(() => {
            modalStore.value.hide()
          }).finally(() => {
            stopPending()
          })
        })
      } else if (isUpdateAction()) {
        validate(function () {
          let id = getCurrentElementVal("id")
          let name = getCategoryForm("name")

          startPending()
          updateApi(id, name).then((resp) => {
            console.log(resp)
            setCurrentElementVal("name", name)

            resetActions()
            resetValidations()

            Notify.success("Категория обновлена");
          }).then(() => {
            modalStore.value.hide()
          }).finally(() => {
            stopPending()
          })
        })
      } else {
        throw new Error("Неверное действие")
      }
    }

    function onDragStart() {
      console.log("onDragStart")
    }

    function onDragEnd(evt) {
      let currentId = parseInt(evt.item.getAttribute("id"))
      let parentId = parseInt(evt.to.getAttribute("id"))

      if (isNaN(parentId)) {
        addDragStore(currentId, 0)
      } else {
        addDragStore(currentId, parentId)
      }
      
      console.log(dragStore.value)
    }

    function addDragStore(id, parentId) {
      dragStore.value[id] = parentId
    }

    function clearDragStore() {
      dragStore.value = {}
    }

    function getDragStore() {
      return dragStore.value
    }

    function hasDragStore() {
      return !Helper.isEmptyObject(getDragStore())
    }

    function deleteApi(ids) {
      return Api.delete(ids)
    }
    
    function updateApi(id, name) {
      return Api.update(id, name)
    }

    function createApi(id, parentId, name) {
      return Api.create(id, parentId, name)
    }

    function moveApi(items) {
      return Api.move(items)
    }

    function isDragMode() {
      return dragMode.value === true
    }

    function isAnyCategories() {
      return treeList.value.length > 0
    }

    function isPendingStore() {
      return pendingStore.value
    }

    function startPending() {
      pendingStore.value = true
    }

    function stopPending() {
      pendingStore.value = false
    }

    function setNoPermission() {
      noPermission.value = true
    }

    function isForbiddenPage() {
      return noPermission.value
    }

    return {
      dragMode,
      categoryForm,
      treeList,
      onCreate,
      onUpdate,
      onDelete,
      onDeleteConfirm,
      onDragStart,
      onDragEnd,
      onStore,
      onActiveDragMode,
      onCancelDragMode,
      onStoreDragMode,
      isEventProcess,
      isCatalogLoading,
      isDragMode,
      isCreateAction,
      isUpdateAction,
      isDeleteAction,
      isAnyCategories,
      isHasCurrentElement,
      isPendingStore,
      isForbiddenPage,
      getCurrentElementVal,
      hasDragStore,
      v$,
    }
  },
})
</script>

<style scoped src="./css/style.css"></style>
