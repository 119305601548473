<template>
  <template v-if="isShowComponent()">
    <div class="card" id="post-composites">
      <div class="card-header">
        <h3 class="card-title">Композиты</h3>

        <label class="row ms-auto" v-if="isViewOnlySelf()">
          <span class="col">Для этого поста</span>
          <span class="col-auto">
            <label class="form-switch">
              <input class="form-check-input" type="checkbox" v-model="onlySelfPost">
            </label>
          </span>
        </label>
      </div>
      <div class="table-responsive">
        <table class="table table-vcenter card-table">
          <thead>
            <tr>
              <th width="50%">Текст</th>
              <th width="15%">Канал</th>
              <th width="15%">Дата создания</th>
              <th width="15%">Автор</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item of getItems()" :key="item.id" :class="getClassBg(item)">
              <td class="text-muted">
                <div class="content" :class="{ 'open': row.isShow(item) }">
                  <div v-if="row.isNotShow(item)" class="hide-element" @click="row.toggleShowPost(item)"></div>
                  {{ row.isNotShow(item) ? '...' : '' }}
                  <div class="mb-3" :class="{ 'inline-content': row.isNotShow(item) }" v-html="element.getCompositeSlice(item)"></div>
                  <span class="button-link" v-if="row.isShow(item)" @click="row.toggleShowPost(item)">
                    Скрыть
                  </span>
                </div>
              </td>
              <td class="channel">
                <img width="20" :src="row.getChannelIcon(item, 30, 30)" alt="">
                <router-link :to="{ name: 'Update', params: { id: element.getCompositeRootPostId(item) } }">{{
                  row.getChannelName(item)
                }}</router-link>
              </td>
              <td class="datetime" v-html="row.getCreatedAt(item)"></td>
              <td class="user">
                <img width="20" :src="row.getUserAvatar(item, 40, 40)">{{ element.getPostUsername(item) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>

  <template v-if="!isReadyPage()">
    <div class="text-center p-1">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </template>
</template>

<script>
import "./css/style.css"
import {defineComponent, onMounted, ref, watch} from "vue"
import Api from "@/modules/post/components/composites/services/Api"
import Image from "@/services/Image"
import element from "@/modules/post/components/composites/services/element"
import DTBackend from "@/services/DTBackend"
import Helper from "@/services/Helper"

export default defineComponent({
  name: "Composites",
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    let originItems = ref([])
    let onlySelfPost = ref(false)
    let readyPage = ref(false)
    let postId = props.data["postId"]
    let hasInChain = props.data["hasInChain"]
    let items = ref([])
    let row = {
      getCreatedAt: (item) => {
        let result, dt, createdAt = element.getCompositeCreatedAt(item)

        if (createdAt === null) {
          result = "Не опубликован"
        } else {
          dt = new DTBackend(createdAt)
          result = dt.get("%D.%M.%y <span>%H:%I</span>")
        }

        return result
      },

      getUserAvatar: (item, w = null, h = null) => {
        let host = element.getPostUserAvatarHost(item)
        let name = element.getPostUserAvatarName(item)

        return Image.handle(host, name, w, h)
      },

      getChannelName: (item) => {
        let name = element.getPostChannelName(item)

        return name
      },

      getChannelIcon: (item, w = null, h = null) => {
        let host = element.getChannelImageHost(item)
        let name = element.getChannelImageName(item)

        if (host === null || name === null) {
          throw Error("Отсутствует иконка")
        }

        return Image.handle(host, name, w, h)
      },
      isShow: (item) => {
        return element.getShow(item)
      },
      isNotShow: (item) => {
        return !element.getShow(item)
      },
      toggleShowPost: (item) => {
        element.setShow(item, !element.getShow(item))
      },
    }

    watch(() => onlySelfPost.value, (val) => {
      if (val === true) {
        let items = getOriginItems().filter(item => {
          return item["post_id"] === props.data["postId"]
        })

        if (items.length === 0 || items.length !== getOriginItems.length) {
          setItems(items)
        }
      } else {
        setItems(getOriginItems())
      }
    })

    onMounted(() => {
      init()
    })

    function init() {
      Api.lists(postId).then((resp) => {
        setItems(resp)
        setOriginItems(resp)
      }).finally(() => {
        readyPageOn()
      })
    }

    function isShowComponent() {
      return (
        isReadyPage() && hasItems()
      )
        || (
          isViewOnlySelf() && hasOriginItems()
        )
    }

    function isReadyPage() {
      return readyPage.value === true
    }

    function readyPageOn() {
      readyPage.value = true
    }

    function setItems(val) {
      items.value = val
    }

    function setOriginItems(val) {
      originItems.value = val
    }

    function getItems() {
      return items.value
    }

    function getOriginItems() {
      return originItems.value
    }

    function hasItems() {
      return !Helper.isEmptyObject(items.value)
    }

    function hasOriginItems() {
      return !Helper.isEmptyObject(originItems.value)
    }

    function isViewOnlySelf() {
      return hasInChain
    }

    function getClassBg(item) {
      return Helper.getClassBgActive(element.isCompositeActive(item))
    }

    return {
      getItems,
      element,
      row,
      isShowComponent,
      isReadyPage,
      onlySelfPost,
      isViewOnlySelf,
      hasOriginItems,
      getClassBg,
    }
  }
})
</script>

<style scoped></style>
