<template>
    <metainfo></metainfo>
  
    <div class="container-xl">
      <div class="page-header d-print-none">
        <div class="row g-2 align-items-center">
          <div class="mt-2">
            <CountCommentModeration :payload="{
              channel_id: 13,
            }"/>
          </div>
          <div class="col-sm">
            <h2 class="page-title">
              <Title :substring="getTitle()" :count-coupons="countCoupons"/>
            </h2>
          </div>
          <div class="col-auto">
            <span class="me-1">
              <router-link class="btn btn-primary" :to="{name: 'CouponCreate'}">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <circle cx="12" cy="12" r="9"></circle>
                  <line x1="9" y1="12" x2="15" y2="12"></line>
                  <line x1="12" y1="9" x2="12" y2="15"></line>
                </svg>
                Создать
              </router-link>
            </span>
            <span class="me-1">
              <template v-if="isPage.coupons()">
                <router-link class="btn btn-dark" :to="{name: 'CouponsDisabled'}">
                  Черновики
                </router-link>
              </template>
              <template v-if="isPage.drafts()">
                <router-link class="btn btn-dark" :to="{name: 'CouponItems'}">
                  Скидки
                </router-link>
              </template>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl">
        <div class="mb-3">
          <Filter @onFilter="onFilter" :enable="filter.enable" :params="filter.params" />
        </div>
        <div ref="scrollComponent">
          <List :query="query" @onRender="onRender" :clear-result="clearResult" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {defineComponent, ref, onMounted, onUnmounted} from "vue"
  import Helper from "@/services/Helper"
  import {useMeta} from "vue-meta"
  import Filter from "@/modules/promopushka/components/filter/Index"
  import List from "@/modules/promopushka/components/list/Index"
  import Title from "@/modules/promopushka/components/title/Title"
  import PostStoreCommon from "@/modules/post/pages/posts/services/PostStoreCommon"
  import CountCommentModeration from "@/components/count_comment_moderation/Index"

  export default defineComponent({
    name: "Coupons",
    components: {
      CountCommentModeration,
      Filter,
      List,
      Title
    },
    props: {
      object: {
        type: Object,
        required: true,
      },
      params: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      useMeta({title: props.params.title})
  
      let filterParams = props.object.filterParams()
  
      let filter = {
        params: {
          search: filterParams.params.search ?? "",
          date: filterParams.params.date ?? null,
          users: filterParams.params.users ?? [],
          statuses: {
            enabled: filterParams.params.statuses.enabled ?? true,
            disabled: filterParams.params.statuses.disabled ?? false,
          },
        },
        enable: filterParams.enable ?? true,
      }
  
      let query = ref({})
      let clearResult = ref(false)
      let offset = ref(0)
      let eofResult = ref(false)
      let countCoupons = ref(0)
  
      function onRender(payload) {
        eofResult.value = payload["oef_items"] === true
        if (payload["count_items"] > 0) {
          countCoupons.value = payload["count_items"];
        }
        clearResult.value = false
        isLoading.value = false
      }
  
      function onFilter(q) {
        clearResult.value = true
        offset.value = 0
        eofResult.value = false
        countCoupons.value = 0
  
        q = Helper.mergeObject(q, {
          "limit": 18,
          "offset": offset.value,
          "page": props.params.page,
        })
  
        q = props.object.prepareQuery(q)
  
        query.value = q
      }
  
      function onLoadMore(offset) {
        if (Helper.isEmptyObject(query.value)) {
          return false
        }
  
        let q = Helper.clone(query.value)
  
        query.value = Helper.mergeObject(q, {
          "offset": offset,
        })
      }
  
      onMounted(() => {
        PostStoreCommon.handle({
          "channel_id": 13,
          "route_name": "Promopushka",
        })

         window.addEventListener("scroll", handleScroll)
      })
  
      onUnmounted(() => {
         window.removeEventListener("scroll", handleScroll)
      })
  
      const scrollComponent = ref(null)
      let isLoading = ref(false)
  
      let scroll = () => {
        isLoading.value = true
        offset.value += 18
  
        onLoadMore(offset.value)
      }
  
      const handleScroll = () => {
        if (eofResult.value) return
  
        let element = scrollComponent.value
  
        if (!isLoading.value && element.getBoundingClientRect().bottom < window.innerHeight) {
          scroll()
        }
      }
  
      let isPage = {
        coupons: () => {
          return props.params.page === "items"
        },
        drafts: () => {
          return props.params.page === "disabled"
        },
      }

      function getTitle() {
        return isPage.coupons() ? 'Скидки' : 'Черновики'
      }
  
      return {
        getTitle,
        onFilter,
        query,
        scrollComponent,
        onRender,
        clearResult,
        filter,
        countCoupons,
        isPage
      }
    },
  })
  </script>
  
  <style scoped>
  
  </style>
  