<template>
  <div class="card" id="type-commerce">
    <div class="card-body">
      <div class="divide-y">
        <label class="row">
          <span class="col-4">
            <span class="title">Тип поста</span>
          </span>
          <span class="col-8">
            <select class="form-select" v-model="fields.type_commerce" @change="onChange">
              <option :key="val.id" :value="val.id" v-for="val in getValues()">{{ val.name }}</option>
            </select>

            <div class="mb-3">
              <span :class="{'is-invalid': v$.type_commerce.$invalid && v$.type_commerce.$dirty}"></span>
              <div class="invalid-feedback" v-for="error of v$.type_commerce.$silentErrors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {defineComponent, ref, watch, onMounted, reactive} from "vue"
import {between} from "@/plugins/vuelidator"
import {useVuelidate} from "@vuelidate/core/dist/index.esm"

export default defineComponent({
  name: "TypeCommerce",
  props: {
    data: {
      type: [Object, null],
      default: null,
    },
  },
  setup(props, {emit}) {
    let values = ref([
      {
        "id": null,
        "name": "Не выбрано",
      },
      {
        "id": 1,
        "name": "Тип-1",
      },
      {
        "id": 2,
        "name": "Тип-2",
      },
      {
        "id": 3,
        "name": "Тип-3",
      },
      {
        "id": 4,
        "name": "Тип-4",
      },
      {
        "id": 5,
        "name": "Тип-5",
      },
      {
        "id": 6,
        "name": "Тип-6",
      },
      {
        "id": 7,
        "name": "Тип-7",
      },
      {
        "id": 8,
        "name": "Тип-8",
      },
      {
        "id": 9,
        "name": "Без оплаты",
      },
    ])

    let selectVal = ref(null)

    let $externalResults = reactive({})

    const fields = reactive({
      type_commerce: selectVal.value,
    })

    const rules = {
      type_commerce: {
        between: between(1, 9)
      },
    }

    const v$ = useVuelidate(rules, fields, {$externalResults})

    function validate(fn) {
      v$.value.$clearExternalResults()

      return v$.value.$validate().then(async () => {
        await fn()
      })
    }

    onMounted(() => {
      setCurrentValue(props.data["val"])
    })

    watch(() => props.data["val"], (val) => {
      setCurrentValue(val)
    })

    watch(() => props.data["validations"], (val) => {
      validate(() => {
        if (val?.type_commerce !== undefined) {
          $externalResults.type_commerce = val["type_commerce"][0]
        }
      })
    })

    function setCurrentValue(val) {
      fields.type_commerce = val;
    }

    function getValues() {
      return values.value;
    }

    function onChange() {
      validate(() => {})
      changeEmit(fields.type_commerce)
    }

    function changeEmit(val) {
      emit("onChange", val)
    }

    return {
      onChange,
      getValues,
      v$,
      fields,
    }
  }
})
</script>

<style scoped>

</style>
