import VueAxios from "vue-axios"
import axios from "axios"
import AuthToken from "@/modules/auth/services/Token"
import AuthClient from "@/modules/auth/services/Client"
import Notify from "@/services/Notify"
import Helper from "@/services/Helper"
import ApiBase from "@/modules/auth/services/ApiBase"
import cfg from "@/services/Config"

axios.defaults.baseURL = Helper.getApiUrl()

axios.interceptors.request.use((request) => {
    let token = AuthToken.get()
    let client = AuthClient.get()

    // todo хак, выпилить
    if (request.url.search('http://87.249.37.193:1007') === 0) {
        request.headers['Authorization'] = 'Bearer ' + cfg._("user_bearer_token")
    } else {
        request.headers['Authorization'] = `Bearer ${token}`
        request.headers['Client'] = client
    }

    return request
})

axios.interceptors.response.use(function (response) {
    return response;
}, function (resp) {
    if (resp.response.status === 401) {
        let client = AuthClient.get()
        if (!client) {
            Notify.error("Необходимо авторизоваться");
            return Promise.reject(resp)
        }

        return ApiBase.refresh({
            token: AuthToken.get(),
            client,
        }).then(response => {
            const item = response.data["item"]
            const token = item["token"]

            AuthToken.set(token);

            return axios.request(resp.config);
        }).catch(() => {
            return Promise.reject(resp)
        });
    } else {
        const data = resp.response.data;

        if (data['status_code'] === 2 && data['validations_common'] !== undefined) {
            Object.entries(data['validations_common']).forEach(([key, val]) => {
                console.log(key);
                Notify.error(val);
            })
        } else if (data['status_code'] !== 0 && data['msg'] !== undefined) {
            Notify.error(data['msg'])
        }

        return Promise.reject(resp)
    }
});

export {VueAxios, axios}
