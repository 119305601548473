<template>
    <div>
      <div class="card">
        <div class="card-header">
            <h3 class="card-title">Загрузить купоны</h3>
            
            <div class="card-actions">
                <a href="/assets/static/promopushka/example.csv" class="btn btn-sm btn-info">Скачать пример файла</a>
            </div>
        </div>
  
        <div class="card-body">
            <div class="mb-3">
                <p>Максимум 50 000 купонов за раз. Формат файла: .csv, .txt</p>
                <div class="fallback">
                    <input type="file" class="form-control" @change="onChange" accept=".txt,.csv"/>
                </div>
            </div>

            <div class="mb-3" v-if="v$.file.$invalid">
                <span :class="{'is-invalid': v$.file.$invalid && v$.file.$dirty}"></span>
                <div class="invalid-feedback" v-for="error of v$.file.$silentErrors" :key="error.$uid">
                    {{ error.$message }}
                </div>
            </div>

            <div class="mb-3" v-if="isLoadedFile">
                <ul>
                    <li v-for="item in getListCoupons().slice(0, 5)" :key="item">{{ item }}</li>
                    <li>...</li>
                    <li>Всего: <b>{{ getListCoupons().length }}</b></li>
                </ul>
            </div>

            <div v-if="isLoadedFile">
                <button class="btn btn-green w-100" :class="{ 'disabled': isUploading }" @click="handleUpload">
                    <span class="spinner-border spinner-border-sm me-2" v-show="isUploading"></span>
                    Загрузить
                </button>
            </div>
        </div>
      </div>
    </div>
</template>
  
<script>
import {defineComponent, reactive, ref, onMounted, watch} from "vue"
import {minLength} from "@/plugins/vuelidator"
import {useVuelidate} from "@vuelidate/core"
import Api from "@/modules/promopushka/pages/edit/services/Api"
import Notify from "@/services/Notify"

export default defineComponent({
    name: "CouponListUpload",
    components: {},
    props: {
        coupon: {
            type: Number,
            required: true,
        },
    },
    setup(props, {emit}) {
        let couponId = ref(0)

        onMounted(() => {
            couponId.value = props.coupon
        })

        watch(() => props.coupon, (val) => {
            couponId.value = val
        })

        let listCoupons = ref([])
        let listCouponsRaw = ref([])
        let $externalResults = reactive({})
        let isLoadedFile = ref(false)
        let isUploading = ref(false)

        const fields = reactive({
            file: [],
        })

        const rules = {
            file: {
                between: minLength(0)
            },
        }

        const v$ = useVuelidate(rules, fields, {$externalResults})

        function validate(fn) {
            v$.value.$clearExternalResults()
            return v$.value.$validate().then(() => {
                fn()
            })
        }

        function onChange(e) {
            fields.file = e
            readFile(e.target.files[0])
        }

        function readFile(file) {
            let reader = new FileReader()
            reader.onload = () => {
                listCouponsRaw.value = reader.result
                listCoupons.value = listCouponsRaw.value.split("\n")
            }
            reader.addEventListener("loadend", loadend);
            reader.readAsText(file)
        }

        function loadend() {
            isLoadedFile.value = true
        }

        function getListCoupons() {
            return listCoupons.value
        }

        function handleUpload() {
            isUploading.value = true
            Api.uploadCouponList({
                id: couponId.value,
                file: listCouponsRaw.value,
            }).then((data) => {
                Notify.success('Успешно загружено ' + data.uploaded + ' купонов.')
                emit("onChange")
            }).catch((resp) => {
                Notify.error('Не удалось загрузить купоны.')
                if (resp.response.data.data.errors !== undefined) {
                    let errors = resp.response.data.data.errors
                    validate(() => {
                        if (errors.file !== undefined) {
                            $externalResults.file = errors.file[0]
                        }
                    })
                }
            }).finally(() => {
                listCoupons.value = []
                listCouponsRaw.value = []
                isLoadedFile.value = false
                isUploading.value = false
                fields.file.target.value = null
            })
        }

        return {
            onChange,
            v$,
            fields,
            isLoadedFile,
            getListCoupons,
            isUploading,
            handleUpload
        }
    }
})
</script>

<style scoped>

</style>
  