<template>
  <metainfo></metainfo>

  <div class="container-xl">
    <div class="page-header d-print-none">
      <div class="row g-2 align-items-center">
        <div class="mt-2">
          <CountCommentModeration :payload="{
            channel_id: getChannelId(),
          }"/>
        </div>
        <div class="col-sm">
          <h2 class="page-title">
            <Title :channel-id="getChannelId()" :title-page="params.h1" :count-posts="countPosts"/>
          </h2>
        </div>
        <div class="col-auto">
          <Actions :channel-id="getChannelId()" :page-name="getCurrentPage()"></Actions>
        </div>
      </div>
    </div>
  </div>
  <div class="page-body">
    <div class="container-xl">
      <div class="mb-3">
        <PostFilter @onFilter="onFilter" :enable="postFilter.enable" :params="postFilter.params" :save-form="postFilter.saveForm" />
      </div>
      <div ref="scrollComponent">
        <PostList :query="query" @onRender="onRender" :clear-result="clearResult" />
      </div>
    </div>
  </div>
</template>

<script>
import Title from "../title/Title"
import Actions from "../actions/Actions"
import {defineComponent, ref, onMounted, onUnmounted, reactive} from "vue"
import {useRoute} from "vue-router"
import PostFilter from "@/modules/post/components/filter/Filter"
import PostList from "@/modules/post/components/list/List"
import Helper from "@/services/Helper"
import {useMeta} from "vue-meta"
import PostStoreCommon from "@/modules/post/pages/posts/services/PostStoreCommon"
import CountCommentModeration from "@/components/count_comment_moderation/Index"

export default defineComponent({
  name: "Posts",
  components: {
    PostList,
    PostFilter,
    Title,
    Actions,
    CountCommentModeration,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    useMeta({title: props.params.title})

    const route = useRoute()

    let filterParams = props.object.filterParams()

    let postFilter = {
      params: {
        search: filterParams.params.search ?? "",
        date: filterParams.params.date ?? null,
        users: filterParams.params.users ?? [],
        statuses: {
          published: filterParams.params.statuses.published ?? true,
          scheduled: filterParams.params.statuses.scheduled ?? true,
          removed: filterParams.params.statuses.removed ?? false,
          draft: filterParams.params.statuses.draft ?? false,
        },
      },
      saveForm: filterParams.saveForm ?? true,
      enable: filterParams.enable ?? true,
    }

    let paramsQuery = reactive({
      channelId: parseInt(route.params["channel_id"]),
    })

    let query = ref({})
    let clearResult = ref(false)
    let offset = ref(0)
    let eofResult = ref(false)
    let countPosts = ref(0)

    function onRender(payload) {
      eofResult.value = payload["oef_items"] === true
      if (payload["count_items"] > 0) {
        countPosts.value = payload["count_items"];
      }
      clearResult.value = false
      isLoading.value = false
    }

    function onFilter(q) {
      clearResult.value = true
      offset.value = 0
      eofResult.value = false
      countPosts.value = 0

      q = Helper.mergeObject(q, {
        "limit": 18,
        "offset": offset.value,
        "channel_id": getChannelId(),
        "page": props.params.page,
      })

      q = props.object.prepareQuery(q)

      query.value = q
    }

    function onLoadMore(offset) {
      if (Helper.isEmptyObject(query.value)) {
        return false
      }

      let q = Helper.clone(query.value)

      query.value = Helper.mergeObject(q, {
        "offset": offset,
      })
    }

    onMounted(() => {
      PostStoreCommon.handle({
        "channel_id": getChannelId(),
        "route_name": "Posts",
      })

      window.addEventListener("scroll", handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll)
    })

    const scrollComponent = ref(null)
    let isLoading = ref(false)

    let scroll = () => {
      isLoading.value = true
      offset.value += 18

      onLoadMore(offset.value)
    }

    const handleScroll = () => {
      if (eofResult.value) return

      let element = scrollComponent.value

      if (!isLoading.value && element.getBoundingClientRect().bottom < window.innerHeight) {
        scroll()
      }
    }

    function getCurrentPage() {
      return props.params.page
    }

    function getChannelId() {
      return paramsQuery.channelId
    }

    return {
      onFilter,
      query,
      scrollComponent,
      onRender,
      clearResult,
      paramsQuery,
      postFilter,
      getCurrentPage,
      getChannelId,
      countPosts,
    }
  },
})
</script>

<style scoped>

</style>
