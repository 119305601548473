import cfg from "@/services/Config"

export default class Helper {
  static isArray(val) {
    return typeof val === "object" && Array.isArray(val) && val !== null;
  }

  static isObject(val) {
    return typeof val === "object" && !Array.isArray(val) && val !== null;
  }

  static strtr(s, p, r) {
    return (
      !!s &&
      {
        2: () => {
          for (let i in p) {
            s = this.strtr(s, i, p[i]);
          }
          return s;
        },
        3: function () {
          return s.replace(RegExp(p, "g"), r);
        },
        0: function () {
          return;
        },
      }[arguments.length]()
    );
  }

  static clone(obj) {
    return Object.assign({}, obj);
  }

  static mergeObject(obj, payload) {
    return Object.assign(obj, payload);
  }

  static isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }

  static isInt(value) {
    let x = parseFloat(value);
    return !isNaN(value) && (x | 0) === x;
  }

  static isString(value) {
    return typeof value === "string" || value instanceof String;
  }

  static objectFilter(obj, callback) {
    return Object.fromEntries(
      Object.entries(obj).filter(([key, val]) => callback(val, key))
    );
  }

  static inArray(needle, haystack) {
    return needle.every(val => haystack.includes(val));
  }

  static formatMoney(number, decimals, decPoint, thousandsSep) {
    let i, j, kw, kd, km;

    if (isNaN((decimals = Math.abs(decimals)))) {
      decimals = 2;
    }

    if (decPoint === undefined) {
      decPoint = ","
    }

    if (thousandsSep === undefined) {
      thousandsSep = "."
    }

    i = parseInt((number = (+number || 0).toFixed(decimals))) + ""

    if ((j = i.length) > 3) {
      j = j % 3;
    } else {
      j = 0;
    }

    km = j ? i.substr(0, j) + thousandsSep : ""
    kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSep);

    if (Math.abs(number - i) !== 0) {
      kd = decimals
        ? decPoint +
          Math.abs(number - i)
            .toFixed(decimals)
            .replace(/-/, 0)
            .slice(2)
        : ""
    } else {
      kd = ""
    }

    return km + kw + kd;
  }

  static compareNumeric(a, b) {
    if (a < b) return 1;
    if (a == b) return 0;
    if (a > b) return -1;
  }

  static getClassBgItems(status) {
    return {
      "draft-post": status === "draft",
      "scheduled-post": status === "scheduled",
      "remove-post": status === "deleted",
      "pre-published-post": status === "prePublished",
      "pre-deleted-post": status === "preDeleted",
    };
  }

  static getClassBgActive(item) {
    return item ? "" : "remove-post"
  }

  static getContentShort(item) {
    let contentShort = item;
    if (contentShort) {
      let regExpBr = /<br>{1,3}/gim;
      contentShort = contentShort.replace(regExpBr, " ");
      let regExpP = /<p>|<\/p>/g;
      contentShort = contentShort.replace(regExpP, " ");
      contentShort = "<p>" + contentShort + "</p>"
    }
    return contentShort;
  }

  static isDeveloperUser(userId) {
    return Helper.inArray([userId], [1, 2, 26, 29]);
  }

  static isEnvLocal() {
    return cfg._("env_mode") === "local"
  }

  static isConfigBase() {
    return location.protocol === "https:"
  }

  static getApiUrl() {
    if (Helper.isEnvLocal()) {
      return cfg._("base", "api_url");
    }

    return Helper.isConfigBase()
      ? cfg._("base", "api_url")
      : cfg._("base_alter", "api_url");
  }

  static getApiUrlPost() {
    if (Helper.isEnvLocal()) {
      return cfg._("base", "api_url_post");
    }

    return Helper.isConfigBase()
      ? cfg._("base", "api_url_post")
      : cfg._("base_alter", "api_url_post");
  }

  static getAdminBot() {
    return cfg._("admin_bot")
  }

  static isEmptyOrNull(str) {
    return str === null || str === ""
  }
}
