<script>
import {defineComponent, ref, onMounted, watch} from "vue"
import Notify from "@/services/Notify"
import Helper from "@/services/Helper"
import Api from "./services/Api"
import element from "./services/element"
import DTBackend from "@/services/DTBackend"
import UserImage from "./services/UserImage"
import Lightbox from "@/components/lightbox/Lightbox"

import {
  allCategory,
  approvedCategory,
  newCategory,
  rejectedCategory,
  removedCategory,
} from "@/components/comment_moderation/services/types"

export default defineComponent({
  name: "CommentModeration",
  components: {
    Lightbox,
  },
  props: {
    payload: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    let categoryList = [
      allCategory,
      newCategory,
      approvedCategory,
      rejectedCategory,
      removedCategory,
    ]
    let hasBeenLoadOnce = ref(false)
    let err = ref("")
    let error = {
      hasError: () => {
        return err.value !== ""
      },
      getError: () => {
        return err.value
      }
    }
    let params = {
      getPostId: () => {
        const postId = props.payload.filter?.post_id

        if (postId === undefined) {
          return null
        }

        if (typeof postId !== 'number') {
          return null
        }

        return postId
      },
      getObjectTypeId: () => {
        return props.payload.filter?.object_type_id ?? null
      },
      getActiveCategory: () => {
        let activeCategory = props.payload.active_category ?? null

        if (activeCategory === null) {
          let categories = params.getCategories()

          if (categories === null) {
            activeCategory = allCategory
          } else {
            activeCategory = categories[0]
          }
        }

        return activeCategory
      },
      isForceShow: () => {
        return props.payload.force_show === true
      },
      getTitle: () => {
        return props.payload.title ?? ""
      },
      getUserIds: () => {
        return props.payload.filter?.user_ids ?? []
      },
      getChannels: () => {
        return props.payload.filter?.channels ?? []
      },
      getQueryString: () => {
        return props.payload.filter?.query_string ?? ""
      },
      getFromDate: () => {
        return props.payload.filter?.from_date ?? ""
      },
      getToDate: () => {
        return props.payload.filter?.to_date ?? ""
      },
      hasTitle: () => {
        return params.getTitle() !== ""
      },
      getLimit: () => {
        let limit = props.payload.limit

        if (limit <= 0 || limit > 100) {
          limit = 15
        }

        return limit
      },
      getCategories: () => {
        const categories = props.payload.categories

        if (categories === undefined) {
          return null
        }

        if (!Helper.inArray(categories, categoryList)) {
          return null
        }

        return categories
      }
    }
    const comments = {
      loadComponent: ref(true),
      selectAll: ref(false),
      page: ref(1),
      showParent: ref({}),
      showFullText: ref({}),
      edit: ref({}),
      contentRefs: ref({}),
      selectItems: ref({}),
      items: ref([]),
      activeCategory: ref(params.getActiveCategory()),
      loadEmptyItems: ref(false),
      element: {
        getId: (item) => {
          return element.getId(item)
        },
        getUserName: (item) => {
          return element.getUserName(item)
        },
        getText: (item) => {
          return element.getText(item)
        },
        getCreated: (item) => {
          let result, dt, createdAt = element.getCreated(item)

          dt = new DTBackend(createdAt).correctTZ()
          result = dt.get("%D.%M.%y %H:%I")

          return result
        },
        getAvatar: (item, w, h) => {
          return UserImage.handle(element.getAvatarHost(item), element.getAvatarName(item), w, h)
        },
        hasParent: (item) => {
          return element.getParentId(item) !== 0
        },
        getParentUserName: (item) => {
          return element.getParentUserName(item)
        },
        getParentText: (item) => {
          return element.getParentText(item)
        },
        getParentAvatar: (item, w, h) => {
          return UserImage.handle(element.getParentAvatarHost(item), element.getParentAvatarName(item), w, h)
        },
        getImages: (item, w, h) => {
          let images = []
          let items = element.getImages(item)

          for (let prop in items) {
            let obj = items[prop]
            images.push(
                UserImage.handle(obj["image_host"], obj["image_name"], w, h)
            )
          }

          return images
        },
        hasImages: (item) => {
          return element.getImages(item).length > 0
        },
        getModeratorUserName: (item) => {
          return element.getModeratorUserName(item)
        },
        getModeratorAvatar: (item, w, h) => {
          return UserImage.handle(element.getModeratorAvatarHost(item), element.getModeratorAvatarName(item), w, h)
        },
        getIsModerate: (item) => {
          return element.getIsModerate(item)
        },
        getIsReject: (item) => {
          return element.getReject(item)
        },
        getPublisherLink: (item) => {
          return element.getPublisherLink(item)
        },
      },
      isActiveCategory: (name) => {
        return name === comments.activeCategory.value
      },
      isShowCategory: (name) => {
        return Helper.inArray([name], params.getCategories())
      },
      loadComments: (query, limit, clear) => {
        if (clear === true) {
          comments.clearItems()
          comments.loadEmptyItems.value = false
        }

        comments.loading.loaders.baseLoadStatus.start()

        comments.api.getComments(query, limit).then((items) => {
          if (Helper.isEmptyObject(items)) {
            comments.loadEmptyItems.value = true
          }
          
          comments.items.value.push(...items)
          if (items.length) {
            markLoadOnce()
          }
        }).finally(() => {
          comments.disableLoadComponent()
          comments.loading.loaders.baseLoadStatus.stop()
        })
      },
      clearItems: () => {
        comments.items.value = []
      },
      disableSelectItems: () => {
        comments.items.value.forEach(item => {
          let id = item["id"]

          delete comments.selectItems.value[id]
        });
      },
      toggleAll: () => {
        comments.items.value.forEach(item => {
          let id = item["id"]

          if (comments.selectAll) {
            comments.selectItems.value[id] = true
          } else {
            delete comments.selectItems.value[id]
          }
        });
      },
      toggleAllLabel: () => {
        if (comments.selectAll.value !== undefined) {
          comments.selectAll = comments.selectAll.value
        }

        comments.selectAll = !comments.selectAll
        comments.toggleAll()
      },
      isChecked: (id) => {
        return comments.selectItems.value[id] !== undefined && comments.selectItems.value[id]
      },
      toggleItem: (id) => {
        if (comments.selectItems.value[id] === true) {
          delete comments.selectItems.value[id]
        } else {
          comments.selectItems.value[id] = true
        }
      },
      removeComments: (ids) => {
        comments.items.value = comments.items.value.filter((item) => {
          return !ids.includes(item.id)
        });
      },
      initLoaders: () => {
        comments.loading.statusesProp.forEach((prop)  => {
          let key = `${prop}Status`

          comments.loading.loaders[key] = comments.createLoader(comments.loading[key])
        })
      },
      bindTextarea: (id, el) => {
        comments.contentRefs.value[id] = el
      },
      getTextareaValue: (key) => {
        const textareaElement = comments.contentRefs.value[key];
        if (!textareaElement) {
          throw new Error("Не найден элемент Textarea")
        }

        return textareaElement.value
      },
      createLoader: (statusRef) => ({
        start(id) {
          if (typeof id !== 'undefined') {
            statusRef.value[id] = true;
          } else {
            statusRef.value = true;
          }
        },
        stop(id) {
          if (typeof id !== 'undefined') {
            statusRef.value[id] = false;
          } else {
            statusRef.value = false;
          }
        },
        isProcess(id) {
          if (typeof id !== 'undefined') {
            return !!statusRef.value[id];
          } else {
            return !!statusRef.value;
          }
        }
      }),
      isLoadComponent: () => {
        return comments.loadComponent.value === true
      },
      disableLoadComponent: () => {
        return comments.loadComponent.value = false
      },
      isShowFullText: (id) => {
        return comments.showFullText.value[id]
      },
      clearShowParent: () => {
        return comments.showParent.value = {}
      },
      isShowParent: (id) => {
        return comments.showParent.value[id]
      },
      isCommentEdit: (id) => {
        return comments.edit.value[id]
      },
      isShowNextPage: () => {
        return comments.loadEmptyItems.value === false && !comments.loading.loaders.baseLoadStatus.isProcess()
      },
      hasItems: () => {
        return Object.keys(comments.items.value).length > 0
      },
      disableSelectAll: () => {
        comments.selectAll = false
      },
      approve: {
        isDisabledBtn: (id) => {
          return comments.loading.loaders.approveLoadStatus.isProcess(id) ||
              !Helper.inArray([comments.activeCategory.value], [newCategory, rejectedCategory])
        },
      },
      reject: {
        isDisabledBtn: (id) => {
          return comments.loading.loaders.rejectLoadStatus.isProcess(id) ||
              !Helper.inArray([comments.activeCategory.value], [newCategory, approvedCategory])
        },
      },
      approveSelect: {
        isDisabledBtn: () => {
          return comments.loading.loaders.approveSelectStatus.isProcess() ||
              Helper.isEmptyObject(comments.selectItems.value) ||
              !Helper.inArray([comments.activeCategory.value], [newCategory, rejectedCategory])
        },
      },
      rejectSelect: {
        isDisabledBtn: () => {
          return comments.loading.loaders.rejectSelectStatus.isProcess() ||
              Helper.isEmptyObject(comments.selectItems.value) ||
              !Helper.inArray([comments.activeCategory.value], [newCategory, approvedCategory])
        },
      },
      api: {
        async getComments(query, limit) {
          return await Api.comments(query, limit)
        },
        async onEdit(id, query) {
          return await Api.edit(id, query)
        },
        async onApprove(query) {
          return await Api.approve(query)
        },
        async onReject(query) {
          return await Api.reject(query)
        },
      },
      events: {
        onCategory: (name) => {
          comments.activeCategory.value = name
          comments.disableSelectAll()
          comments.disableSelectItems()
          comments.clearShowParent()
          comments.page.value = 1

          comments.loadComments({
            "object_id": params.getPostId(),
            "object_type_id": params.getObjectTypeId(),
            "category": comments.activeCategory.value,
            "page": 1,
            "user_ids": params.getUserIds(),
            "min_created": params.getFromDate(),
            "max_created": params.getToDate(),
            "channels": params.getChannels(),
            "query_string": params.getQueryString(),
          }, params.getLimit(), true)
        },
        onShowParent: (id) => {
          let result = !comments.showParent.value[id]

          comments.showParent.value[id] = result
          comments.events.onText(id, result)
        },
        onEdit: (id) => {
          comments.edit.value[id] = !comments.edit.value[id]
        },
        closeEdit: (id) => {
          comments.edit.value[id] = false
        },
        onEditApi: (id) => {
          comments.loading.loaders.saveLoadStatus.start(id)

          comments.api.onEdit(id, {
            "comment_text": comments.getTextareaValue(id)
          }).then(() => {
            Notify.success("Отредактировано")

            comments.events.closeEdit(id)
          }).catch(() => {
            Notify.error("Ошибка редактирования")
          }).finally(() => {
            comments.loading.loaders.saveLoadStatus.stop(id)
          })
        },
        onApproveApi: (id) => {
          comments.loading.loaders.approveLoadStatus.start(id)

          comments.api.onApprove({
            "comment_ids": [id],
          }).then(() => {
            comments.disableSelectAll()
            comments.disableSelectItems()
            comments.removeComments([id])

            Notify.success("Одобрено")
          }).catch(() => {
            Notify.error("Ошибка одобрения")
          }).finally(() => {
            comments.loading.loaders.approveLoadStatus.stop(id)
          })
        },
        onRejectApi: (id) => {
          comments.loading.loaders.rejectLoadStatus.start(id)

          comments.api.onReject({
            "comment_ids": [id],
          }).then(() => {
            comments.disableSelectAll()
            comments.disableSelectItems()
            comments.removeComments([id])

            Notify.success("Отклонено")
          }).catch(() => {
            Notify.error("Ошибка отклонения")
          }).finally(() => {
            comments.loading.loaders.rejectLoadStatus.stop(id)
          })
        },
        onApproveSelect: () => {
          let ids = []
          Object.entries(comments.selectItems.value).forEach(([key]) => {
            ids.push(Number(key))
          });

          if (ids.length <= 0) {
            return
          }

          comments.loading.loaders.approveSelectStatus.start()

          comments.api.onApprove({
            "comment_ids": ids,
          }).then(() => {
            comments.disableSelectAll()
            comments.disableSelectItems()
            comments.removeComments(ids)

            Notify.success("Выбранные комментарии одобрены")
          }).catch(() => {
            Notify.error("Ошибка одобрения")
          }).finally(() => {
            comments.loading.loaders.approveSelectStatus.stop()
          })
        },
        onRejectSelect: () => {
          let ids = []
          Object.entries(comments.selectItems.value).forEach(([key]) => {
            ids.push(Number(key))
          });

          if (ids.length <= 0) {
            return
          }

          comments.loading.loaders.rejectSelectStatus.start()

          comments.api.onReject({
            "comment_ids": ids,
          }).then(() => {
            comments.disableSelectAll()
            comments.disableSelectItems()
            comments.removeComments(ids)

            Notify.success("Выбранные комментарии отклонены")
          }).catch(() => {
            Notify.error("Ошибка отклонения")
          }).finally(() => {
            comments.loading.loaders.rejectSelectStatus.stop()
          })
        },
        onNextPage: () => {
          comments.page.value += 1

          comments.loadComments({
            "object_id": params.getPostId(),
            "object_type_id": params.getObjectTypeId(),
            "category": getActiveCategory(),
            "page": comments.page.value,
            "user_ids": params.getUserIds(),
            "min_created": params.getFromDate(),
            "max_created": params.getToDate(),
            "channels": params.getChannels(),
            "query_string": params.getQueryString(),
          }, params.getLimit(), false)
        },
        onText: (id, force) => {
          if (force !== undefined) {
            comments.showFullText.value[id] = force
          } else {
            comments.showFullText.value[id] = !comments.showFullText.value[id]
          }
        },
        goSourceLink(item) {
          let link = element.getPublisherLink(item)

          if (!link) {
            Notify.error("Ссылка не найдена!")
            return
          }

          window.open(link, '_blank');
        }
      },
      loading: {
        baseLoadStatus: ref(false),
        saveLoadStatus: ref({}),
        approveLoadStatus: ref({}),
        rejectLoadStatus: ref({}),
        approveSelectStatus: ref(false),
        rejectSelectStatus: ref(false),
        statusesProp: [
          "baseLoad",
          "saveLoad",
          "approveLoad",
          "rejectLoad",
          "approveSelect",
          "rejectSelect",
        ],
        loaders: {},
      },
    }

    onMounted(() => {
      if (Helper.isEmptyObject(props.payload)) {
        return
      }

      if (checkPayload() === false) {
        return
      }

      init()
    })

    watch(() => props.payload, () => {
      if (checkPayload() === false) {
        return
      }

      comments.activeCategory.value = getActiveCategory()
      comments.page.value = 1

      init()
    })

    function checkPayload() {
      if (params.getCategories() === null) {
        comments.disableLoadComponent()
        setError("Неверный тип категории")

        return false
      }

      return true
    }

    function getActiveCategory() {
      return comments.activeCategory.value
    }

    function init() {
      comments.initLoaders()

      comments.loadComments({
        "object_id": params.getPostId(),
        "object_type_id": params.getObjectTypeId(),
        "category": getActiveCategory(),
        "page": comments.page.value,
        "user_ids": params.getUserIds(),
        "min_created": params.getFromDate(),
        "max_created": params.getToDate(),
        "channels": params.getChannels(),
        "query_string": params.getQueryString(),
      }, params.getLimit(), true)
    }

    function isShowComponent() {
      return (isHasBeenLoadOnce() || (params.isForceShow() && !comments.isLoadComponent())) && !hasError()
    }

    function setError(msg) {
      err.value = msg
    }

    function hasError() {
      return err.value !== ""
    }

    function countCategories() {
      let categories = params.getCategories()
      if (categories === null) {
        return -1
      }

      return params.getCategories().length
    }

    function isHasBeenLoadOnce() {
      return hasBeenLoadOnce.value === true
    }

    function markLoadOnce() {
      return hasBeenLoadOnce.value = true
    }

    return {
      error,
      comments,
      isShowComponent,
      categoryList: {
        allCategory,
        approvedCategory,
        newCategory,
        rejectedCategory,
        removedCategory,
      },
      countCategories,
      params,
    }
  }
})
</script>

<template>
  <div class="row comment-moderation-list" id="comment-moderation-list" v-if="isShowComponent()">
    <div class="col">
      <div class="card mb-3">
        <div class="card-header align-items-center" v-if="params.hasTitle()">
          <div class="col">
            <h3 class="card-title">
              <span class="comment-select-all">{{ params.getTitle() }}</span>
            </h3>
          </div>
        </div>
        <div class="card-header" v-if="countCategories() > 1">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item" @click="comments.events.onCategory(categoryList.allCategory)" v-if="comments.isShowCategory(categoryList.allCategory)">
              <a class="nav-link" :class="{'active': comments.isActiveCategory(categoryList.allCategory)}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon dropdown-item-icon icon-tabler icons-tabler-outline icon-tabler-list-letters"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M11 6h9" /><path d="M11 12h9" /><path d="M11 18h9" /><path d="M4 10v-4.5a1.5 1.5 0 0 1 3 0v4.5" /><path d="M4 8h3" /><path d="M4 20h1.5a1.5 1.5 0 0 0 0 -3h-1.5h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6z" /></svg>
                Все
              </a>
            </li>
            <li class="nav-item" @click="comments.events.onCategory(categoryList.newCategory)" v-if="comments.isShowCategory(categoryList.newCategory)">
              <a class="nav-link" :class="{'active': comments.isActiveCategory(categoryList.newCategory)}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon dropdown-item-icon icon-tabler icons-tabler-outline icon-tabler-brand-telegram"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" /></svg>
                Новые
              </a>
            </li>
            <li class="nav-item" @click="comments.events.onCategory(categoryList.approvedCategory)" v-if="comments.isShowCategory(categoryList.approvedCategory)">
              <a class="nav-link" :class="{'active': comments.isActiveCategory(categoryList.approvedCategory)}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon dropdown-item-icon icon-tabler icons-tabler-outline icon-tabler-circle-check">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                  <path d="M9 12l2 2l4 -4" />
                </svg>
                Одобренные
              </a>
            </li>
            <li class="nav-item" @click="comments.events.onCategory(categoryList.rejectedCategory)" v-if="comments.isShowCategory(categoryList.rejectedCategory)">
              <a class="nav-link" :class="{'active': comments.isActiveCategory(categoryList.rejectedCategory)}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon dropdown-item-icon icon-tabler icons-tabler-outline icon-tabler-circle-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M10 10l4 4m0 -4l-4 4" /></svg>
                Отклоненные
              </a>
            </li>
            <li class="nav-item" @click="comments.events.onCategory(categoryList.removedCategory)" v-if="comments.isShowCategory(categoryList.removedCategory)">
              <a class="nav-link" :class="{'active': comments.isActiveCategory(categoryList.removedCategory)}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon dropdown-item-icon icon-tabler icons-tabler-outline icon-tabler-trash"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                Удаленные
              </a>
            </li>
          </ul>
        </div>
        <div class="card-header align-items-center" v-if="comments.hasItems()">
          <div class="col">
            <h3 class="card-title">
              <input class="form-check-input me-1" type="checkbox" v-model="comments.selectAll" @change="comments.toggleAll()">
              <span class="comment-select-all ms-2" @click="comments.toggleAllLabel()">выбрать всё</span>
            </h3>
          </div>
          <div class="col-auto">
            <button title="Одобрить выбранные" class="btn btn-green btn-responsive-sm btn-icon" @click="comments.events.onApproveSelect()" :class="{'disabled': comments.approveSelect.isDisabledBtn()}">
              <template v-if="comments.loading.loaders.approveSelectStatus.isProcess()">
                <span class="spinner-border spinner-border-sm" role="status"></span>
              </template>
              <template v-else>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon dropdown-item-icon icon-tabler icons-tabler-outline icon-tabler-circle-check">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                  <path d="M9 12l2 2l4 -4" />
                </svg>
              </template>
            </button>
            <button title="Отклонить выбранные" class="btn btn-red btn-responsive-sm btn-icon ms-2" @click="comments.events.onRejectSelect()" :class="{'disabled': comments.rejectSelect.isDisabledBtn()}">
              <template v-if="comments.loading.loaders.rejectSelectStatus.isProcess()">
                <span class="spinner-border spinner-border-sm" role="status"></span>
              </template>
              <template v-else>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon dropdown-item-icon icon-tabler icons-tabler-outline icon-tabler-circle-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M10 10l4 4m0 -4l-4 4" /></svg>
              </template>
            </button>
          </div>
        </div>
        <div class="card-table table-responsive">
          <div class="comment-moderation-empty" v-if="!comments.hasItems() && !comments.loading.loaders.baseLoadStatus.isProcess()">
            <span>Комментариев не найдено</span>
          </div>
          <table class="comment-moderation-table table table-vcenter table-hover" v-if="comments.hasItems()">
            <tbody>
              <!--table-danger-->
              <!--table-primary-->
              <!--table-light-->
              <tr class="table-light" v-for="comment in comments.items.value" :key="comments.element.getId(comment)">
                <td class="comment-moderation-select">
                  <input class="form-check-input" type="checkbox" :checked="comments.isChecked(comments.element.getId(comment))" @change="comments.toggleItem(comments.element.getId(comment))">
                </td>
                <td class="comment-moderation-content" :class="{'comment-moderation-edit': comments.isCommentEdit(comments.element.getId(comment))}">
                  <div class="list-group list-group-flush list-group-hoverable" :class="{'comment-moderation-parent-show': comments.isShowParent(comments.element.getId(comment))}">
                    <div class="comment-moderation-parent">
                      <div class="align-items-center">
                        <span class="comment-moderation-user">
                          <template v-if="comments.element.hasParent(comment)">
                            <div class="col-auto comment-moderation-avatar">
                              <span class="avatar" :style="{ backgroundImage: 'url('+ comments.element.getParentAvatar(comment, 60, 60) +')'}"></span>
                            </div>
                            <div class="col ms-2">
                              <span class="text-reset d-block comment-moderation-author">{{ comments.element.getParentUserName(comment) }}</span>
                              <div class="d-block text-secondary">{{ comments.element.getParentText(comment) }}</div>
                            </div>
                          </template>
                        </span>
                        <div class="list-group list-group-flush list-group-hoverable">
                          <div class="comment-moderation-children">
                            <div class="align-items-center comment-moderation-children-item">
                              <div class="col-auto comment-moderation-avatar" @click="comments.toggleItem(comments.element.getId(comment))">
                                <span class="avatar" :style="{ backgroundImage: 'url('+ comments.element.getAvatar(comment, 60, 60) +')'}"></span>
                              </div>
                              <div class="col ms-2">
                                <span class="text-reset d-block comment-moderation-author">{{ comments.element.getUserName(comment) }}</span>
                                <div class="d-block comment-moderation-edit-content">
                                  <textarea :key="comments.element.getId(comment)" :ref="el => comments.bindTextarea(comments.element.getId(comment), el)" class="w-100" v-model="comment['text']"></textarea>
                                </div>
                                <div class="d-block comment-moderation-show-content" :class="{'open': comments.isShowFullText(comments.element.getId(comment))}">
                                  <div class="comment-moderation-images mt-1" v-if="comments.element.hasImages(comment)">
                                    <Lightbox :images="{thumb: comments.element.getImages(comment, 70), origin: comments.element.getImages(comment)}"></Lightbox>
                                  </div>
                                  <span @click="comments.events.onText(comments.element.getId(comment))">{{ comments.element.getText(comment) }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-center mt-1 comment-moderation-edit-btns">
                              <div class="col text-end">
                                <button title="Отменить" class="btn btn-red btn-responsive-sm ms-2 btn-icon" @click="comments.events.closeEdit(comments.element.getId(comment))">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-back">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
                                  </svg>
                                </button>
                                <button title="Сохранить" class="btn btn-primary btn-responsive-sm ms-2" :class="{'disabled': comments.loading.loaders.saveLoadStatus.isProcess(comments.element.getId(comment))}" @click="comments.events.onEditApi(comments.element.getId(comment))">
                                  <span class="spinner-border spinner-border-sm me-2" role="status" v-if="comments.loading.loaders.saveLoadStatus.isProcess(comments.element.getId(comment))"></span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-device-floppy">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />
                                    <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                    <path d="M14 4l0 4l-6 0l0 -4" />
                                  </svg>
                                  <span class="btn-responsive-text">Сохранить</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="comment-moderation-moderation1">
                  <button title="Перейти в пост" class="btn btn-responsive-sm btn-icon ms-2" @click="comments.events.goSourceLink(comment)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-link">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <path d="M9 15l6 -6" />
                      <path d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464" />
                      <path d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463" />
                    </svg>
                  </button>
                  <template v-if="comments.element.hasParent(comment)">
                    <button title="Посмотреть родительский комментарий" class="btn btn-responsive-sm btn-icon ms-2" @click="comments.events.onShowParent(comments.element.getId(comment))">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-list-tree">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M9 6h11" />
                        <path d="M12 12h8" />
                        <path d="M15 18h5" />
                        <path d="M5 6v.01" />
                        <path d="M8 12v.01" />
                        <path d="M11 18v.01" />
                      </svg>
                    </button>
                  </template>
                  <button title="Редактировать" class="btn btn-responsive-sm btn-icon ms-2" @click="comments.events.onEdit(comments.element.getId(comment))">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-edit">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                      <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                      <path d="M16 5l3 3" />
                    </svg>
                  </button>
                </td>
                <td class="comment-moderation-info">
                  <div class="dropdown-menu dropdown-menu-demo">
                    <span class="dropdown-item">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon dropdown-item-icon icon-tabler icons-tabler-outline icon-tabler-calendar-month">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z" />
                        <path d="M16 3v4" />
                        <path d="M8 3v4" />
                        <path d="M4 11h16" />
                        <path d="M7 14h.013" />
                        <path d="M10.01 14h.005" />
                        <path d="M13.01 14h.005" />
                        <path d="M16.015 14h.005" />
                        <path d="M13.015 17h.005" />
                        <path d="M7.01 17h.005" />
                        <path d="M10.01 17h.005" />
                      </svg>
                      {{ comments.element.getCreated(comment) }}
                    </span>
                    <span class="dropdown-item" v-if="comments.element.getIsModerate(comment) || comments.element.getIsReject(comment)">
                      <template v-if="comments.element.getIsModerate(comment)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="me-2 icon-approve"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M9 12l2 2l4 -4" /></svg>
                      </template>
                      <template v-if="comments.element.getIsReject(comment)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="me-2 icon-reject"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M10 10l4 4m0 -4l-4 4" /></svg>
                      </template>
                      <span class="avatar avatar-xs rounded me-2" :style="{ backgroundImage: 'url('+ comments.element.getModeratorAvatar(comment, 60, 60) +')'}"></span>
                      {{ comments.element.getModeratorUserName(comment) }}
                    </span>
                  </div>
                </td>
                <td class="text-end comment-moderation-moderation2">
                  <button title="Одобрить" class="btn btn-green btn-responsive-sm btn-icon" @click="comments.events.onApproveApi(comments.element.getId(comment))" :class="{'disabled': comments.approve.isDisabledBtn(comments.element.getId(comment))}">
                    <template v-if="comments.loading.loaders.approveLoadStatus.isProcess(comments.element.getId(comment))">
                      <span class="spinner-border spinner-border-sm" role="status"></span>
                    </template>
                    <template v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-circle-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M9 12l2 2l4 -4" /></svg>
                    </template>
                  </button>
                  <button title="Отклонить" class="btn btn-red btn-responsive-sm btn-icon ms-2" @click="comments.events.onRejectApi(comments.element.getId(comment))" :class="{'disabled': comments.reject.isDisabledBtn(comments.element.getId(comment))}">
                    <template v-if="comments.loading.loaders.rejectLoadStatus.isProcess(comments.element.getId(comment))">
                      <span class="spinner-border spinner-border-sm" role="status"></span>
                    </template>
                    <template v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon dropdown-item-icon icon-tabler icons-tabler-outline icon-tabler-circle-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M10 10l4 4m0 -4l-4 4" /></svg>
                    </template>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="comment-moderation-loading" v-if="comments.loading.loaders.baseLoadStatus.isProcess()">
            <div class="spinner-border"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="d-flex justify-content-center">
          <span class="btn btn-primary" @click.prevent="comments.events.onNextPage()" :class="{'disabled': !comments.isShowNextPage()}">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-loader"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 6l0 -3" /><path d="M16.25 7.75l2.15 -2.15" /><path d="M18 12l3 0" /><path d="M16.25 16.25l2.15 2.15" /><path d="M12 18l0 3" /><path d="M7.75 16.25l-2.15 2.15" /><path d="M6 12l-3 0" /><path d="M7.75 7.75l-2.15 -2.15" /></svg>
            Загрузить ещё
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="mb-3">
        <div class="card-table table-responsive">
          <div class="comment-moderation-loading" v-if="comments.isLoadComponent()">
            <div class="spinner-border"></div>
          </div>
          <div class="comment-moderation-error" v-if="error.hasError()">
            <span>{{ error.getError() }} [comment_moderation]</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.comment-moderation-content {
  min-width: 400px;
  height: 100%;
}

.comment-moderation-parent .comment-moderation-avatar, .comment-moderation-children .comment-moderation-avatar {
  padding-left: 0;
}

.comment-moderation-children-item {
  display: flex;
}

.comment-moderation-parent .comment-moderation-user {
  display: none;
}

.comment-moderation-parent-show .comment-moderation-parent .comment-moderation-user {
  display: flex;
}

.comment-moderation-parent-show .comment-moderation-children {
  padding: 5px var(--tblr-list-group-item-padding-x) 0 47px;
  margin-top: 5px;
}

@media (min-width: 296px) and (max-width: 1399px) {
  .comment-moderation-moderation1, .comment-moderation-moderation2 {
    width: 80px !important;
  }
}

.comment-moderation-moderation1 {
  min-width: 150px !important;
}

.comment-moderation-moderation1 button.btn {
  border: none;
}

.comment-moderation-moderation1 button svg {
  color: #000;
}

.comment-moderation-moderation2 {
  min-width: 120px !important;
}

@media (min-width: 296px) and (max-width: 1399px) {
  .comment-moderation-moderation2 button, .comment-moderation-moderation1 button {
    margin-top: 5px;
  }
}

.comment-moderation-table {
  height: 100%;
}

.comment-moderation-table td textarea {
  border: 1px solid #0000000f;
  outline: none;
  background: #8181811c;
  border-radius: 5px;
  height: 200px;
  padding: 10px;
}

.comment-moderation-table td {
  padding: 10px 5px;
}

@media (max-width: 768px) {
  .comment-moderation-table td {
    padding: 3px 5px;
  }
}

.comment-moderation-list .dropdown-item {
  padding: 5px 0;
}

.comment-moderation-list .dropdown-item:hover {
  background: none;
}

.comment-moderation-author {
  font-weight: 600;
}

.comment-moderation-info {
  width: 120px;
}

.comment-moderation-info .avatar {
  border-radius: 15px !important;
}

.comment-moderation-info .dropdown-menu {
  box-shadow: none;
  border: none;
  --tblr-dropdown-bg: none !important;
}

.comment-moderation-info .icon-approve {
  color: #2fb344
}

.comment-moderation-info .icon-reject {
  color: #d63939
}

.comment-moderation-empty {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.comment-moderation-error {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.comment-moderation-error span {
  color: #ff0000;
}

.comment-moderation-loading {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.comment-moderation-loading .spinner-border {
  --tblr-spinner-width: 2.25rem;
  --tblr-spinner-height: 2.25rem;
}

.comment-moderation-edit .comment-moderation-edit-content, .comment-moderation-edit .comment-moderation-edit-btns {
  display: block !important;
}

.comment-moderation-edit-content {
  display: none !important;
}

.comment-moderation-edit-btns {
  display: none !important;
}

.comment-moderation-edit .comment-moderation-show-content {
  display: none !important;
}

.comment-moderation-show-content.open {
  max-height: 100% !important;
}

.comment-moderation-show-content span:hover {
  cursor: pointer;
}

.comment-moderation-show-content {
  max-height: 40px;
  overflow: hidden;
}

.pagination .page-item {
  outline: none;
}

.comment-moderation-images {
  display: inline-block;
  margin-right: 10px;
}

.comment-moderation-show-content.open .comment-moderation-images {
  display: block;
  margin-bottom: 5px;
}

.comment-select-all:hover {
  cursor: pointer;
}

.comment-moderation-list .nav-item:hover {
  cursor: pointer;
}

.comment-moderation-select {
  width: 45px;
}
</style>

<style>
#comment-moderation-list .comment-moderation-images .lightbox-image {
  margin-right: 10px;
  width: 50px;
}
#comment-moderation-list .comment-moderation-images .lightbox-image:last-child {
  margin-right: 0;
}
</style>
